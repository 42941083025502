import React from 'react';
import Form from '../footer';
import styles from './education.module.css';
import { Accordion, AccordionItem } from 'react-sanfona';
import Picture from '../../assets/imgs/education.png';

export default () => {
    return (
        <div className={styles.mainBox}>
            <div className={styles.topImage}>
                <h1 className={styles.masterHeader}>Master your <span style={{color:'red'}}>art and skill</span></h1>
                <img src={Picture} alt='' className={styles.eduImg}/>
            </div>
            <div>
                <h1 className={styles.masterHeader}>Taking your art to the &nbsp;<p style={{color:'red'}}>master level.</p></h1>
                <hr style={{width:'100px',marginTop:40, marginBottom:40, border:'1px solid black'}}/>
                <p style={{marginBottom:'20px', lineHeight:'26px', fontSize:'18px'}}>FiHair’s diploma courses won’t just broaden your horizons and open new doors: they will also help you to master your art and skill as a hairdresser. Discover the FiHair ethos as you work, and learn with leading professionals who have worked with some of the biggest names in the industry.</p>
                <ul style={{marginLeft:'50px', lineHeight:'26px', fontSize:'18px'}}>
                    <li>Work with dedicated stylists</li>
                    <li>Advance your career</li>
                    <li>Network with some of the hottest talent today</li>
                </ul>
                <p style={{marginTop:'20px', marginBottom:'50px', lineHeight:'26px', fontSize:'18px'}}>P.S. Don’t forget, the FiHair team is always available to hire for educational demonstrations and location visits at any time!</p>
                <a href="/contact" className={styles.contactlink}>Learn More</a>
            </div>
            <Accordion allowMultiple style={{marginTop:'50px', marginBottom:'50px'}}>
                <AccordionItem title={"1:1"} style={{color:'red', margin:'10px', marginBottom:'20px', fontSize:'20px', borderBottom:'1px solid black'}}>
                <div>
                    <h3 style={{marginTop:'10px', color:'black', fontSize:'18px'}}>A high energy and informative day with one of our educators! This one or two day, hands-on workshop is designed for the stylist who wants to sharpen his or her existing skills in a personalized, one-to-one environment. This program is a fusion of Structured Formation Part I, Part II, and Blow-Dry & Finish.</h3>
                    <p style={{marginTop:10, fontStyle:'italic', color:'black', fontSize:'17px'}}>Two models/clients required for this course.</p>
                    <h3 style={{marginTop:20, fontWeight:'bold'}}>Program Content</h3>
                    <ul style={{marginTop:20, marginLeft:40, lineHeight:'25px', color:'black', fontSize:'17px'}}>
                        <li>Structured Formation 1</li>
                        <li>Structured Formation 2</li>
                        <li>Structured Formation 3</li>
                        <li>Blow Dry & Finish</li>
                    </ul>
                </div>
                </AccordionItem>
                <AccordionItem title={"Blow Dry & Finish"} style={{color:'red', margin:'10px', fontSize:'20px', borderBottom:'1px solid black' , marginBottom:'20px'}}>
                <div>
                    <h3 style={{marginTop:'10px', color:'black', fontSize:'18px'}}>This one-day, hands on program designed to give the hairdresser a complete understanding of the augmentation involved with the blow dry and how to finish with flair. Learn to appreciate the true art of the fashionable finish; polished, relaxed and lasting shape control. This class is designed for all levels and is paced according to the stylist’s abilities and experience.</h3>
                    <h3 style={{marginTop:20, fontWeight:'bold'}}>Program Content</h3>
                    <ul style={{marginTop:20, marginLeft:40, lineHeight:'25px', color:'black', fontSize:'17px'}}>
                        <li>Prepping the hair</li>
                        <li>Power-drying</li>
                        <li>Sectioning</li>
                        <li>Tool selection</li>
                        <li>Heat and speed selection</li>
                        <li>Brush setting</li>
                        <li>Scrunching</li>
                        <li>Wave do’</li>
                        <li>Up do’s</li>
                        <li>Blow-drying the bob</li>
                        <li>Balance</li>
                        <li>Silhouette</li>
                        <li>When to stop</li>
                    </ul>
                </div>
                </AccordionItem>
                <AccordionItem title={"Structured Formation 1"} style={{color:'red', margin:'10px', fontSize:'20px', borderBottom:'1px solid black' , marginBottom:'20px'}}>
                <div>
                    <h3 style={{marginTop:'10px', color:'black', fontSize:'18px'}}>This one-day, hands-on workshop is ideal for the stylist who would like to reinforce his or her cutting disciplines. The team at Fi Hair will explain the fundamental techniques of executing a planned and effective shape in the hair to help stylists increase salon clientele and create client loyalty. Expand your creativity by practicing and learning the importance of the consultation for the client’s hair needs.</h3>
                    <h3 style={{marginTop:20, fontWeight:'bold'}}>Program Content</h3>
                    <ul style={{marginTop:20, marginLeft:40, lineHeight:'25px', color:'black', fontSize:'17px'}}>
                        <li>The square</li>
                        <li>Combing</li>
                        <li>Sectioning</li>
                        <li>Standing positions</li>
                        <li>Holding the hair</li>
                        <li>Reference points</li>
                        <li>Visualizing shape</li>
                        <li>Frontal Layering</li>
                        <li>Checking the cut</li>
                        <li>Where to start cutting</li>
                        <li>When to stop cutting</li>
                    </ul>
                </div>
                </AccordionItem>
                <AccordionItem title={"Structured Formation 2"} style={{color:'red', margin:'10px', fontSize:'20px', borderBottom:'1px solid black' , marginBottom:'20px'}}>
                <div>
                    <p style={{marginTop:'10px', marginBottom:'10px', fontSize:'17px'}}>Pre-Requisite – Structured Formation 1</p>
                    <h3 style={{color:'black', fontSize:'18px'}}>This one-day, hands on workshop will travel deeper into the square and how it’s applied to create new shapes. This program will enable the stylist to alter any shape while maintaining control of each section. Enhance your comfort with creativity, break the rules, and take your cutting skills to the next level. This comprehensive overview of all the modern classics in haircutting will help you reinforce and refresh existing cutting skills, while you learn the subtleties of precision. Structured Formation 2 will provide the hairdresser with more confidence and vision when consulting with any type of client.</h3>
                    <h3 style={{marginTop:20, fontWeight:'bold'}}>Program Content</h3>
                    <ul style={{marginTop:20, marginLeft:40, lineHeight:'25px', color:'black', fontSize:'17px'}}>
                        <li>The square</li>
                        <li>Combing</li>
                        <li>Sectioning</li>
                        <li>Standing positions</li>
                        <li>Client positions</li>
                        <li>Graduated Bob</li>
                        <li>Diagonal Square</li>
                        <li>Texturing</li>
                        <li>Visualizing shape</li>
                        <li>Personalizing</li>
                    </ul>
                </div>
                </AccordionItem>
                <AccordionItem title={"Structured Formation 3"} style={{color:'red', margin:'10px', fontSize:'20px', borderBottom:'1px solid black' , marginBottom:'20px'}}>
                <div>
                    <p style={{marginTop:'10px', marginBottom:'10px', fontSize:'17px'}}>Pre-Requisite – Structured Formation 2</p>
                    <h3 style={{color:'black', fontSize:'18px'}}>This one day hands on workshop completes the Structured Formation Trilogy. Structured Formation three is an extension of 1 and 2 will explore new ideas with the square technique while maintaining the disciplines required in achieving these shapes.</h3>
                    <h3 style={{marginTop:20, fontWeight:'bold'}}>Program Content</h3>
                    <ul style={{marginTop:20, marginLeft:40, lineHeight:'25px', color:'black', fontSize:'17px'}}>
                        <li>Shifting the square</li>
                        <li>Combing</li>
                        <li>Sectioning</li>
                        <li>Standing positions</li>
                        <li>Holding the hair</li>
                        <li>Visualizing shape</li>
                        <li>Razor cutting</li>
                    </ul>
                </div>
                </AccordionItem>
            </Accordion>
            <Form/>
        </div>
    )
}
