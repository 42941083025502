import React from 'react';
import { Route, Switch }  from 'react-router-dom';
import Home from './components/home/';
import Education from './components/education';
import Distributors from './components/distributors';
import Contact from './components/contact/';
import NotFound from './components/404';
export default () => (
    <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/education" component={Education} />
        <Route path="/distributors" component={Distributors} />
        <Route path="/contact" component={Contact} />
        <Route component={NotFound} />
    </Switch>
);
