import React from 'react';
import styles from './home.module.css';
import Form from '../../footer';
import ImgViewer from '../../imageViewer';

//logos imports
import Logo from '../../../assets/imgs/fihairbrush.png';
import Red from './Original-Gretchen.png';
import Pink from './pink.png';
import Bbm from './bbm.png';
import Cc from './cc.png';
import Ss from './ss.jpg';
import T46 from './T-46.png';
import Wilhelmina from './wilhelmina.png';
import Troubadour from './troubadour.png';
import Tailbone from './tailbone.png';
import Vent from './vent.png';

//Images imports for image viewer
import R1 from './red/1.jpg';
import R2 from './red/2.jpg';
import R3 from './red/3.jpg';
import R4 from './red/4.jpg';
import R5 from './red/5.jpg';
import R6 from './red/6.jpg';

import S1 from './ss/1.jpg';
import C1 from './cc/1.jpg';
import B1 from './bbm/1.jpg';
import T1 from './tail/1.jpg';

import P1 from './pink/1.jpg';
import P2 from './pink/2.jpg';

import T41 from './t46/1.jpg';
import T42 from './t46/2.jpg';
import T43 from './t46/3.jpg';

import TR1 from './trou/1.jpg';
import TR2 from './trou/2.jpg';
import TR3 from './trou/3.jpg';

import V1 from './vent/1.jpg';
import V2 from './vent/2.jpg';
import V3 from './vent/3.jpg';
import V4 from './vent/4.jpg';
import V5 from './vent/5.jpg';
import V6 from './vent/6.jpg';

import W1 from './wil/1.jpg';
import W2 from './wil/2.jpg';

const RedImages = [
    R1,R2,R3,R4,R5,R6
];
const BbmImages = [
    B1
];
const CcImages = [
    C1
];
const SsImages = [
    S1
];
const TailImages = [
    T1
];
const PinkImages = [
    P1,P2
];
const T46Images = [
    T41,T42,T43
];
const TrouImages = [
    TR1,TR2,TR3
];
const VentImages = [
    V1,V2,V3,V4,V5,V6
];
const WilImages = [
    W1,W2
];


export default () =>{
    return (
        <div className={styles.mainBox}>
            <img src={Logo} className={styles.mainImg} alt=""/>
            <h1 className={styles.h1}><p style={{color:'black', borderBottom:'1px solid black'}}>Our</p>  &nbsp;Products</h1>
            <ul className={styles.navOptions}>
                <li className={styles.option}>
                    <a href="#red">Gretchen RED</a>
                </li>
                <li className={styles.option}>
                    <a href="#ss">Gretchen SS</a>
                </li>
                <li className={styles.option}>
                    <a href="#pink">Gretchen PINK</a>
                </li>
                <li className={styles.option}>
                    <a href="#cc">Gretchen CC</a>
                </li>
                <li className={styles.option}>
                    <a href="#bbm">Gretchen BBM</a>
                </li>
                <li className={styles.option}>
                    <a href="#wil">Wilhelmina</a>
                </li>
                <li className={styles.option}>
                    <a href="#vent">VENT</a>
                </li>
                <li className={styles.option}>
                    <a href="#tail">Tailbone</a>
                </li>
                <li className={styles.option}>
                    <a href="#trou">Troubadour</a>
                </li>
                <li className={styles.option}>
                    <a href="#t46">T-46</a>
                </li>
            </ul>
            <div className={styles.productBox}  id="red">
                <h2 className={styles.h2}>Gretchen RED</h2>
                <img src={Red} alt="Gretchen RED" className={styles.productLogo} style={{height:'70px',width:'250px'}}/>
                <p1 className={styles.p1}>THE BRUSH THAT STARTED IT ALL. THE ONE AND ONLY GRETCHEN.</p1>
                <p2 className={styles.p2}>Ceramic-coated barrel eliminates frizz leaving the hair silky & smooth</p2>
                <p3 className={styles.p3}>Ceramic spreads and holds heat longer so hair straightens or curls faster</p3>
                <p2 className={styles.p2}>When heated, ceramic emits negative ions, which helps breakdown bacteria build up</p2>
                <p3 className={styles.p3}>Ionic treated bristles increase shine and reduce static</p3>
                <p2 className={styles.p2}>Unique one-piece handle eliminates snagging</p2>
                <p3 className={styles.p3}>Ideal for straightening the hair</p3>
                <p1 className={styles.p1}>Available in 6 sizes</p1>
                <ImgViewer images={RedImages} advice="space-around"/>
            </div>
            <div className={styles.productBox}  id="ss">
                <h2 className={styles.h2}>GRETCHEN SS</h2>
                <img src={Ss} alt="Gretchen RED" className={styles.productLogo} style={{height:'70px',width:'250px'}}/>
                <p1 className={styles.p1}>THE GRETCHEN SS BUILT FOR SPEED.</p1>
                <p2 className={styles.p2}>Extra-long barrel allows for wider sections which reduces drying time</p2>
                <p3 className={styles.p3}>Ceramic-coated barrel eliminates frizz leaving the hair silky & smooth</p3>
                <p2 className={styles.p2}>Ceramic spreads and holds heat longer so hair straightens or curls faster</p2>
                <p3 className={styles.p3}>When heated, ceramic emits negative ions, which helps breakdown bacteria build up</p3>
                <p2 className={styles.p2}>Ionic treated bristles increase shine and reduce static</p2>
                <p3 className={styles.p3}>Unique one-piece handle eliminates snagging</p3>
                <p2 className={styles.p2}>Ideal for straightening the hair</p2>
                <ImgViewer images={SsImages} advice="space-around"/>
            </div>
            <div className={styles.productBox}  id="pink">
                <h2 className={styles.h2}>GRETCHEN PINK</h2>
                <img src={Pink} alt="Gretchen PINK" className={styles.productLogo} style={{height:'70px',width:'250px'}}/>
                <p1 className={styles.p1}>THE BRUSH THAT STARTED IT ALL. THE ONE AND ONLY GRETCHEN.</p1>
                <p3 className={styles.p3}>Ceramic-coated barrel eliminates frizz leaving the hair silky & smooth</p3>
                <p2 className={styles.p2}>Ceramic spreads and holds heat longer so hair straightens or curls faster</p2>
                <p3 className={styles.p3}>When heated, ceramic emits negative ions, which helps breakdown bacteria build up</p3>
                <p2 className={styles.p2}>Ionic treated bristles increase shine and reduce static</p2>
                <p3 className={styles.p3}>Unique one-piece handle eliminates snagging</p3>
                <p2 className={styles.p2}>Ideal for straightening the hair</p2>
                <p1 className={styles.p1}>Available in 4 sizes</p1>
                <ImgViewer images={PinkImages} advice="space-around"/>
            </div>
            <div className={styles.productBox}  id="cc">
                <h2 className={styles.h2}>GRETCHEN CC</h2>
                <img src={Cc} alt="Gretchen CC" className={styles.productLogo} style={{height:'70px',width:'250px'}}/>
                <p1 className={styles.p1}>CC MY PLAYMATE COME OUT AND PLAY WITH ME</p1>
                <p2 className={styles.p2}>The concave shaped barrel provides greater contact to the head shape and funnels the hair to the center creating better tension and a tighter curl/wave to the hair.</p2>
                <p3 className={styles.p3}>The bristles are crimped providing a softer touch and more give on the scalp and fingers when guiding the hair around the barrel.</p3>
                <p2 className={styles.p2}>Ceramic-coated barrel eliminates frizz leaving the hair silky and smooth. Ceramic spreads and holds heat longer so hair straightens or curls faster. Ionic treated bristles increase shine and reduce static.</p2>
                <p3 className={styles.p3}>Unique one piece handle feels like velvet.</p3>
                <p1 className={styles.p1}>Available in 5 sizes</p1>
                <ImgViewer images={CcImages} advice="space-around"/>
            </div>
            <div className={styles.productBox} id="bbm">
                <h2 className={styles.h2}>GRETCHEN BBM</h2>
                <img src={Bbm} alt="Gretchen BBM" className={styles.productLogo} style={{height:'70px',width:'250px'}}/>
                <p1 className={styles.p1}>PENETRATE THROUGH THE HAIR ADDING SHINE AND CONDITION</p1>
                <p2 className={styles.p2}>Pure root boar bristle tufts penetrate through the hair adding shine and condition.</p2>
                <p3 className={styles.p3}>Ionic nylon bristles penetrate through thicker hair allowing for better tension.</p3>
                <p2 className={styles.p2}>Boar bristles spread the natural hair oil or moisture (sebum) from the hair roots to the tips and avoid dryness of hair.</p2>
                <p3 className={styles.p3}>Hair sebum, which is spread by boar bristles, act as a natural hair conditioner and hence protects the hair from all sorts of physical damages.</p3>
                <p1 className={styles.p1}>Available in 5 sizes</p1>
                <ImgViewer images={BbmImages} advice="space-around"/>
            </div>
            <div className={styles.productBox}  id="wil">
                <h2 className={styles.h2}>Wilhelmina</h2>
                <img src={Wilhelmina} alt="wilhelmina" className={styles.productLogo} style={{height:'70px',width:'250px'}}/>
                <p1 className={styles.p1}> SOFT & FLEXIBLE CUSHION PAD ADJUSTS TO THE HEAD SHAPE</p1>
                <p2 className={styles.p2} style={{fontSize:'22px', marginBottom:'30px'}}>Featured on Martha Stewart Living and Martha Stewart Living magazine “Best Brushes”</p2>
                <p3 className={styles.p3}>Soft and flexible cushion pad adjusts to the head shape for better distribution of natural oils while being gentle on the scalp.</p3>
                <p2 className={styles.p2}>Pure root Boar bristle tufts penetrate through the hair adding shine and condition.</p2>
                <p3 className={styles.p3}>Naturally uneven Boar bristles spread the natural hair oil or moisture (sebum) from the hair roots to the tips and avoid dryness of hair.</p3>
                <p2 className={styles.p2}>Hair sebum, which is spread by boar bristles, act as a natural hair conditioner and hence protects the hair from all sorts of physical damages.</p2>
                <p3 className={styles.p3}>The handle is specifically designed to fit the shape of your hand for comfort & ease of use.</p3>
                <p2 className={styles.p2}>Ideal for up do’s, brush sets and finishing the hair.</p2>
                <p1 className={styles.p1}>Available in 3 sizes - S,M,L</p1>
                <ImgViewer images={WilImages} advice="space-around"/>
            </div>
            <div className={styles.productBox}  id="vent">
                <h2 className={styles.h2}>VENT</h2>
                <img src={Vent} alt="Vent" className={styles.productLogo} style={{height:'70px',width:'250px'}}/>
                <p1 className={styles.p1}>BECAUSE SOMETIMES YOU JUST… HAVE TO VENT</p1>
                <p2 className={styles.p2}>Ball tipped bristles gentle on the scalp and helps detangle wet hair</p2>
                <p3 className={styles.p3}>Tunnel vents allow air to flow through the brush to provide quick drying and styling.</p3>
                <p2 className={styles.p2}>Longer bristles provide maximum lift from the root area.</p2>
                <p3 className={styles.p3}>One piece handle eliminates snagging when drying bobs or longer hair.</p3>
                <p2 className={styles.p2}>Light weight and ergonomically design for increased comfort and feel.</p2>
                <ImgViewer images={VentImages} advice="space-around"/>
            </div>
            <div className={styles.productBox}  id="tail">
                <h2 className={styles.h2}>Tailbone</h2>
                <img src={Tailbone} alt="Tailbone" className={styles.productLogo} style={{height:'50px',width:'250px'}}/>
                <p1 className={styles.p1}>REMOVE TANGLES GENTLY PREVENTING COMBING BREAKAGE</p1>
                <p2 className={styles.p2}>The bone comb can remove tangles gently, preventing combing breakage.</p2>
                <p3 className={styles.p3}>The extra wide teeth allows for natural finishing and detailing of layers</p3>
                <p2 className={styles.p2}>The long tail is perfect for sectioning when blow drying, creating up do’s or finishing.</p2>
                <p3 className={styles.p3}>Because of the extra wide teeth, the Tailbone can be used to comb colours through</p3>
                <p2 className={styles.p2}>Extra long tail comb – 11 1/2 inches.</p2>
                <p3 className={styles.p3}>The Tailbone handmade combs are made out of a 100 percent organic resin called phenotic resin laminate/bakelite.</p3>
                <ImgViewer images={TailImages} advice="space-around"/>
            </div>
            <div className={styles.productBox}  id="trou">
                <h2 className={styles.h2}>Troubadour</h2>
                <img src={Troubadour} alt="Troubadour" className={styles.productLogo} style={{height:'40px',width:'300px'}}/>
                <p1 className={styles.p1}>EXTRA LONG EUROPEAN STYLE HAS ADDED TEETH FOR MORE CONTROL</p1>
                <p2 className={styles.p2}>The evenly spaced teeth provide distribution of hair within the section for cleaner bob lines</p2>
                <p3 className={styles.p3}>Perfect for the low tension cutting techniques needed for curly, wavy, and/or thick and ethnic textures.</p3>
                <p2 className={styles.p2}>Extra long European style comb has added teeth for more control of each section.</p2>
                <p3 className={styles.p3}>The thick/fine teeth combination adds great versatility and is perfect with longer shears and scissor – over comb techniques.</p3>
                <p2 className={styles.p2}>8 inch cutting comb.</p2>
                <p1 className={styles.p1}>Now available in three colours; black, red, and white.</p1>
                <ImgViewer images={TrouImages} advice="start"/>
            </div>
            <div className={styles.productBox}  id="t46">
                <h2 className={styles.h2}>T-46</h2>
                <img src={T46} alt="T-46" className={styles.productLogo} style={{height:'80px',width:'220px'}}/>
                <p1 className={styles.p1}>EXTRA WIDE TEETH ALLOWS FOR LOOSER TENSION</p1>
                <p2 className={styles.p2}>Perfect for shorter hair cuts. Improved control in tight spots.</p2>
                <p3 className={styles.p3}>Extra wide teeth allow for looser tension and easier sectioning.</p3>
                <p2 className={styles.p2}>Ideal for dry cutting medium to thick hair textures.</p2>
                <p3 className={styles.p3}>The T-46 has the right amount of comb flex which eliminates any pulling or snagging.</p3>
                <p1 className={styles.p1}>Available in two colours – red and white.</p1>
                <ImgViewer images={T46Images} advice="start"/>
            </div>
            <div>
                <Form/>
            </div>
        </div>
    );
};