import React from 'react';
import * as emailjs from 'emailjs-com';
import './form.css';

import { Field, Label, Control, Input, Button, Icon, Textarea, Notification } from 'rbx';

class ContactForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const { name, email, subject, message } = this.state;
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'fihairfyi@gmail.com', //fihairfyi
      subject,
      message,
      email,
      name,
      message_html: message,
    };
    emailjs.send(
      'service_ocwu18a',
      'template_2hacen5',
       templateParams,
      'user_u6zd7lATLNgztekmoRlHU'
    )
    this.resetForm();
  };

  resetForm() {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { name, email, subject, message, sentMessage } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className="contact-form">
        <Field className="contact-form-group">
          <Control>
            <Input
              name="name"
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={this.handleChange}
              required
            />
          </Control>
        </Field>
        <Field className="contact-form-group">
          <Control>
            <Input
              name="email"
              type="text"
              placeholder="Email/Phone number"
              value={email}
              onChange={this.handleChange}
              required
            />
          </Control>
        </Field>
        {/*<Field className="contact-form-group">
          <Control>
            <Input
              name="subject"
              type="text"
              placeholder="Subject (optional)"
              value={subject}
              onChange={this.handleChange}
            />
          </Control>
    </Field>*/}
        <Field className="contact-form-group">
          <Control>
            <Textarea
              name="message"
              placeholder="Message"
              value={message}
              onChange={this.handleChange}
            />
          </Control>
        </Field>

        <Field kind="group">
          <Control>
            <Button color="dark" className="submitButton">Send</Button>
          </Control>
          <Control>
            <Button text className="cancelButton">Cancel</Button>
          </Control>
        </Field>
      </form>
    );
  }
}

export default ContactForm;
