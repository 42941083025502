import React from 'react';
import './form.css';
import axios from 'axios';
import ContactForm from './sendMail';

class Form extends React.Component {
    render(){
        return (
            <div>
                <div className="uppertext">
                    <p style={{fontSize:'22px',fontStyle:'italic', margin:'20px'}}>Treat Yourself</p>
                    <h1 style={{fontSize:'36px',fontWeight:'bolder', margin:'20px', color:'red'}}>Get in Touch</h1>
                    <hr style={{width:'100px', marginLeft:'auto', marginRight:'auto', border:'1px solid'}}/>
                </div>
                <ContactForm/>
                <div className="lowertext">
                    <div>
                        <h2 style={{fontSize:'26px',fontWeight:'bolder', marginBottom:'50px'}}>Headquarter</h2>
                        <p style={{fontSize:'20px',fontWeight:'bold', marginBottom:'10px'}}>FiHair</p>
                        <p style={{fontSize:'20px',fontWeight:'bold', marginBottom:'10px'}}>8100 Yonge Street</p>
                        <p style={{fontSize:'20px',fontWeight:'bold', marginBottom:'10px'}}>Thornhill, Ontario</p>
                        <p style={{fontSize:'20px',fontWeight:'bold', marginBottom:'10px'}}>Canada L4J 6W6</p>
                    </div>
                    <div>
                        <p style={{fontSize:'20px',fontWeight:'bold', marginBottom:'10px'}}>Phone: (905) 771 8733</p>
                    </div>
                </div>
                <div className="lowerfooter">
                    <p style={{fontSize:'18px',fontWeight:'bold', color:'white'}}>COPYRIGHTS © FIHAIR 1997 | ALL RIGHTS RESERVED</p>
                </div>
            </div>
        )
    }
}
export default Form;