import React from 'react';
import styles from './distributors.module.css';
import Form from '../footer';
import Picture from '../../assets/imgs/distributors.png';
export default function Distributors() {
    return (
        <div className={styles.mainBox}>
            <div className={styles.topImage}>
                <h1 className={styles.masterHeader}>Top hairdressers love <span style={{color:'red'}}>our products</span></h1>
                <img src={Picture} alt='' className={styles.eduImg}/>
            </div>
            <div>
                <h1 className={styles.masterHeader}>Shaping hairdressing culture <span style={{color:'red'}}>One city at a time.</span></h1>
                <hr style={{width:'100px',marginTop:40, marginBottom:40, border:'1px solid black'}}/>
                <p style={{marginBottom:'20px', lineHeight:'26px', fontSize:'18px'}}>The FiHair family is growing every year, and you can find our distributors across North America at the following locations. Visit your nearest location and find out why top hairdressers love our gear and beauty products!</p>
                <a href="/contact" className={styles.contactlink}>CONTACT FIHAIR</a>
            </div>
            <div>
                <h1 className={styles.masterHeader}>FiHair &nbsp;<p style={{color:'red'}}>Distributors.</p></h1>
                <hr style={{width:'100px',marginTop:40, marginBottom:40, border:'1px solid black'}}/>
                <h2 className={styles.masterHeader} style={{color:'red'}}>US</h2>
                <div className={styles.lowertext}>
                    <div>
                        <h3>ARIZONA</h3>
                        <p style={{color:'red'}}>Adagio Beauty Supply</p>
                        <p style={{color:'red'}}>9619 N Hayden Rd, Suite 110 A/B</p>
                        <p style={{color:'red'}}>Scottsdale, AZ 852258</p>
                        <p style={{color:'grey'}}>Tel: 1.480.836.9560</p>
                    </div>
                    <div>
                        <h3>MICHIGAN</h3>
                        <p style={{color:'red'}}>Prestige Beauty Supply</p>
                        <p style={{color:'red'}}>39580 14 Mile Rd.</p>
                        <p style={{color:'red'}}>Walled Lake, MI 48390</p>
                        <p style={{color:'grey'}}>Tel: 1.248.669.0355</p>
                    </div>
                    <div>
                        <h3>FLORIDA</h3>
                        <p style={{color:'red'}}>Oken Enterprises, Inc</p>
                        <p style={{color:'red'}}>Lyons Business Park</p>
                        <p style={{color:'red'}}>6601 Lyons Rd. Suite D 5-6</p>
                        <p style={{color:'red'}}>Coconut Creek, FL 33073-36000</p>
                        <p style={{color:'grey'}}>Tel: 1.954.419.1616</p>
                    </div>
                </div>
                <h2 className={styles.masterHeader} style={{color:'red'}}>CANADA</h2>
                <div className={styles.lowertext}>
                    <div>
                        <h3>CANADA/USA</h3>
                        <p style={{color:'red'}}>KAO Brands</p>
                        <p style={{color:'red'}}>GOLDWELL / KMS</p>
                        <p style={{color:'grey'}}>Tel: 1.800.333.2442</p>
                    </div>
                </div>
                <h2 className={styles.masterHeader} style={{color:'red'}}>INTERNATIONAL</h2>
                <div className={styles.lowertext}>
                    <div>
                        <h3>UK</h3>
                        <p>Website: <a href='https://www.HairBrushesDirect.com/' style={{color:'red'}}>www.HairBrushesDirect.com</a></p>
                    </div>
                </div>
            </div>
            <Form/>
        </div>
    )
}
