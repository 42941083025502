import React from 'react';
import styles from './contact.module.css';
import Picture from '../../assets/imgs/contact.png';
import Form from '../footer';

export default () => {
    return (
        <div className={styles.mainBox}>
            <div className={styles.topImage}>
                <h1 className={styles.masterHeader}>Let's <span style={{color:'red'}}> talk.</span></h1>
                <img src={Picture} alt='' className={styles.eduImg}/>
            </div>
            <div>
                <h1 className={styles.masterHeader}>For the love &nbsp;<p style={{color:'red'}}>of hair.</p></h1>
                <hr style={{width:'100px',marginTop:40, marginBottom:40, border:'1px solid black'}}/>
                <p style={{marginBottom:'20px', lineHeight:'26px', fontSize:'18px'}}>From the first cut to the last, every day in the hairdressing industry is a journey through creativity and uniqueness. We love to hear stories, whether you’re just starting your journey or you’re a wizard with scissors and styling!</p>
                <p style={{marginTop:'20px', marginBottom:'50px', lineHeight:'26px', fontSize:'18px'}}>Ask us a question, tell us a story or find out more about our products by filling out the form below, and we’ll get right back to you.</p>
                <p style={{marginTop:'20px', marginBottom:'50px', lineHeight:'26px', fontSize:'18px'}}>P.S Why not visit one of our distributors to see our latest products?</p>
                <a href="/contact" className={styles.contactlink}>Learn More</a>
            </div>
            <Form/>
        </div>
    )
}
